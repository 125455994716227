<template>
  <div>
    <div class="card mb-4 custom-card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-8 mb-4"></div>
          <div class="col-sm-4">
            <b-input-group >
              <b-input-group-append>
                <b-input-group-text  class="input_border_radius_left_top_bt">
                  <b-icon icon="search" />
                </b-input-group-text>
              </b-input-group-append>
              <b-form-input placeholder="ระบุการค้นหา เช่น ชื่อซัพพลายเออร์, เบอร์โทรศัพท์, อีเมล, ไลน์, หมายเหตุ"
                            v-model="criteria.search" class="form-control-group" @keyup.enter.native="searchHandler()">
              </b-form-input>
              <b-input-group-append>
                <b-button text="Button" variant="primary" @click="searchHandler()" style="width: 86px;">
                  ค้นหา
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
    <div class="card custom-card">
      <div class="card-body">
        <div class="table-responsive padding_top_20">
          <table class="table align-middle table-bordered">
            <thead>
              <tr class="text-center" >
                <th style="min-width: 50px; width: 1px;">#</th>
                <th>ชื่อซัพพลายเออร์</th>
                <th>เบอร์โทรศัพท์</th>
                <th>อีเมล</th>
                <th>ไลน์</th>
                <th>หมายเหตุ</th>
                <th style="min-width: 50px; width: 1px;">ดำเนินการ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in items" :key="`list-supplier-mapping-location-${index}-${data.id}`">
                <td class="text-center checkbox_style" >{{index+((paging.page-1)*paging.limit)+1}}</td>
                <td>{{data.name}}</td>
                <td>{{data.phone}}</td>
                <td>{{data.email}}</td>
                <td>{{data.line}}</td>
                <td>{{data.remark}}</td>
                <td class="text-center" >
                  <span class="style_icon_btn" v-if="permission.actionData.flag_update">
                    <i class="fas fa-link" @click="onEditHandler(data.id)" title="กำหนดราคาจัดส่ง"></i>
                  </span>
                  <span class="style_icon_btn">
                    <i class="fas fa-eye" @click="onViewHandler(data.id)" title="พรีวิว"></i>
                  </span>
                </td>
              </tr>
              <tr v-if="items.length === 0" class="text-center">
                <td colspan="7">ไม่มีข้อมูล</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row padding_top_20">
      <div  class="col-lg-1 col-md-2 col-3"
            style="margin-top: 30px;">
        <select class="form-control mb-2"
                id="pageLimit"
                style="width: 80px;"
                v-model="paging.limit" v-on:change="changeLimit()">
          <option v-for="(data) in paging.pages"
                  :key="data"
                  :value="data">
            {{data}}
          </option>
        </select>
      </div>
      <div class="col-lg-11">
        <div class="row g-3"  v-if="paging.rowTotal > 0">
          <div  class="col-xl-11 col-lg-10 mb-2 text-right"
                style="margin-top: 30px;">
            <label  class="form-label"
                    style="margin: 8px; font-size: 12px;
                    font-family: Sarabun-Light;
                    color: #818181;">
              แสดงรายการที่ <strong>{{((paging.page-1)*paging.limit)+1}} ถึง {{(paging.page) * paging.limit}}</strong> จาก <strong>{{paging.rowTotal | numberIntFormat}} รายการ </strong>
            </label>
            <paginate v-model="paging.page"
                      :first-last-button="true"
                      :page-count="paging.pageTotal"
                      :page-range="5"
                      :margin-pages="2"
                      :prev-text="'&lt;'"
                      :next-text="'&gt;'"
                      :first-button-text="'&verbar;&lt;'"
                      :last-button-text="'&gt;&verbar;'"
                      :click-handler="changePage"
                      :container-class="'pagination pagination-sm float-right m-auto paging_ul'"
                      :page-class="'page-link'"
                      :prev-class="'page-link prev_paging'"
                      :next-class="'page-link next_paging'">
            </paginate>
          </div>
          <div class="col-xl-1 col-lg-2 padding_left_none">
            <label  class="form-label"
                    style="font-size: 12px;
                    font-family: Sarabun-Light;">
              Go to page
            </label>
            <Select2 v-model="paging.page" :options="paging.pageSizes" @change="changePage(paging.page)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Config from '../../../util/config';
  export default {
    name: 'supplier_mapping_location-view-page-supplier_mapping_location',
    data() {
      return {
        permission:{
          appSlug: 'supplier-mapping-location',
          actionData: {}
        },
        criteria: {
          search: "",
          orderBy: "",
          ascDesc: ""
        },
        items: [],
        paging: {
          page: 1,
          limit: Config.pagination.limit,
          pages: Config.pagination.pages,
          rowTotal: 0,
          pageTotal: 0,
          pageSizes: []
        },
      };
    },
    methods: {
      onEditHandler(id){
        this.SessionStorageUtils.setSession("page-supplier-mapping-location", id);
        this.$router.push(`/supplier-mapping-location/edit/${id}`);
      },
      onViewHandler(id){
        this.SessionStorageUtils.setSession("page-supplier-mapping-location", id);
        this.$router.push(`/supplier-mapping-location/preview/${id}`);
      },

      /******* local function ******/
      changeLimit(){
        this.paging.page = 1;
        this.getListItems();
      },
      changePage (page) {
        this.paging.page = parseInt(""+page);
        this.getListItems();
      },
      sortHandler(orderBy){
        if(orderBy!=this.criteria.orderBy){
          this.criteria.ascDesc = "asc";
        }else{
          this.criteria.ascDesc = this.criteria.ascDesc=="asc"?"desc":"asc";
        }
        this.criteria.orderBy = orderBy;
        this.getListItems();
      },
      searchHandler(){
        this.paging.page = 1;
        this.getListItems();
      },
      async getListItems () {
        const params = `search=${this.criteria.search}&page=${this.paging.page}&limit=${this.paging.limit}&orderBy=${this.criteria.orderBy}&ascDesc=${this.criteria.ascDesc}`;
        const result = await this.HttpServices.getData(`/ab_supplier?${params}`);
        if(result&&result.status.code=="200"){
          const data = result.data;
          var total = Math.ceil(data.rowTotal/this.paging.limit);
          this.paging.pageTotal = total;
          this.paging.rowTotal = data.rowTotal;
          this.items = data.data;
          this.paging.pageSizes = [];
          for(let i=1;i<=total;i++){
            this.paging.pageSizes.push({id: i, text: i});
          }
        }else{
          this.paging.pageSizes = [];
          this.paging.rowTotal = 0;
          this.items = [];
        }
      },
      checkDisabledDelete(){
        let resp = true;
        for(const v of this.items){
          if(v.selected){
            resp = false;
            break;
          }
        }

        return resp;
      },
      async initData(){
        await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
        this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
        await this.getListItems();
      }
      /******* local function ******/
    },
    async mounted() {
      await this.initData();
      this.SessionStorageUtils.deleteSession("page-supplier-mapping-location");
    }
  };
</script>
<style scope>
  .checkbox_style > .custom-control {
    left: 12px;
  }
</style>

<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-truck-loading"></i> ข้อมูลกำหนดราคาจัดส่ง </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <page-supplier-mapping-location />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import pageSupplierMappingLocation from "./view/page.supplier_mapping_location.vue";

  export default {
    name: 'supplier_mapping_location-index',
    components: {
      pageSupplierMappingLocation
    }
  };
</script>
<style>

</style>
